import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { Input } from '@lib/components/v2/Form';
import classNames from 'classnames';
import { localizedString } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import classes from './DetailMatch.style.module.scss';

const DetailMatch = ({ onChange, errors, dirty, submitted, ...restProps }) => {
  const finalLogo = useLogo();

  const {
    DETAIL_MATCH_LABEL = false,
    DETAIL_MATCH = [],
    DETAIL_MATCH_CONTENT = null
  } = process.env;
  const fields = DETAIL_MATCH.map((key) => {
    const value = restProps[key];
    return {
      id: key,
      label: DETAIL_MATCH_LABEL ? DETAIL_MATCH_LABEL[key] : '',
      value: value || ''
    };
  });

  const generateFields = fields.map((field) => {
    const { id, label } = field;

    return (
      <Input
        key={id}
        placeholder={label}
        className={classNames(classes.input)}
        hasError={dirty[id] || submitted ? errors[id] : null}
        onChange={(value) => onChange(id, value)}
        {...field}
      />
    );
  });

  const defaultContent = `${localizedString(
    'forProtectionVerifyThatYourApplication'
  )}<br /><br />${localizedString('enterDetailsBelow')}<br /><br />${localizedString(
    'enterNameAsPerApplication'
  )}`;

  return (
    <div className={classNames(classes.wrapper, 'b-container')}>
      <img alt="" className="b-logo" src={finalLogo} />
      <br />
      <br />
      <div className={classes.title}>
        {DETAIL_MATCH_CONTENT ? parse(DETAIL_MATCH_CONTENT) : parse(defaultContent)}
      </div>
      <div className={classes.container}>{generateFields}</div>
    </div>
  );
};

DetailMatch.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.object,
  dirty: PropTypes.object,
  name: PropTypes.string,
  reference: PropTypes.string,
  submitted: PropTypes.bool
};

DetailMatch.defaultProps = {
  name: '',
  reference: '',
  onChange: () => {}
};

export default DetailMatch;
